export default [
  {
    text: "Общественная деятельность",
    link: { name: "SocialActivity" },
  },
  {
    text: "Трудовая деятельность",
    link: { name: "LaborActivity" },
  },
];
