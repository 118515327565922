<template>
  <div class="activity">
    <Title label="Деятельность" />
    <div class="activity__header">
      <TopSidebar :tab-data="activityBar" />
    </div>
    <router-view />
  </div>
</template>

<script>
import Title from "@/components/Blocks/Title";
import TopSidebar from "@/components/Blocks/TopSidebar";
import activityBar from "@/enums/activityBar";
export default {
  name: "Activity",
  components: { TopSidebar, Title },
  data() {
    return {
      activityBar,
    };
  },
};
</script>

<style scoped></style>
